import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 130px;

  @media (max-width: 768px) {
    left: 0;
  }
`;

const Img1 = styled.img`
  width: 300px;
  height: 480px;
  z-index: 2;

  @media (max-width: 1024px) {
    width: 200px;
    height: 320px;
  }

  @media (max-width: 768px) {
    width: 220px;
    height: 292px;
    order: 1;
    margin-top: 43px;
  }
`;

const moveUpDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
`;

const Img2 = styled.img`
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  animation: ${moveUpDown} 2s ease-in-out infinite alternate;

  @media (max-width: 1024px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
    
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  

  @media (max-width: 1024px) {
    margin-left: 150px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    text-align: center;
    order: 0;
    margin-top: 23px;
  }
`;

const Text1 = styled.div`
  font-size: 50px;
  color: rgba(150, 192, 211, 1);
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Text2 = styled.div`
  font-size: 30px;
  color: rgba(255, 255, 255, 1);
  width: 90%;

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 16px;
  }
  @media only screen and (max-width: 480px) {
  margin-bottom:30px;
    
   
  }
`;

function Exa() {
  const [textRef, textInView] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });

  return (
    <Container>
      <ImageContainer>
        <motion.div
          ref={imageRef}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
          <Img1 src="../images/botAi.png" alt="botAi" />
          <Img2 src="../images/coin.png" alt="coin" />
        </motion.div>
      </ImageContainer>
      <TextContainer>
        <motion.div
          ref={textRef}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: textInView ? 0 : 100, opacity: textInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
          <Text1>Proof of Artificial Intelligence (PoAI)</Text1>
          <Text2>
        PoAI (Proof of Artificial Intelligence) is created to ensure the decentralization of blockchain system fast and safely. Artificial Intelligence based on machine learning will be perfect as it is used and will surpass all the currently used consensuses.
          </Text2>
        </motion.div>
      </TextContainer>
    </Container>
  );
}

export default Exa;

