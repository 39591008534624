
import React from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import AvailabilityComponent from './AvailabilityComponent';

const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom:10%;

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 992px) {
  }

  @media only screen and (max-width: 768px) {
    height: 100vh;
  }

  @media only screen and (max-width: 576px) {
  }

  @media only screen and (max-width: 480px) {
  }

  @media only screen and (max-width: 360px) {
  }
`;

const Container = styled.div`
  height: 100%;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    width: 1000px;
  }

  @media only screen and (max-width: 992px) {
    width: 800px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 576px) {
  }

  @media only screen and (max-width: 480px) {
  }

  @media only screen and (max-width: 360px) {
  }
`;

const Left = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;

  @media only screen and (max-width: 768px) {
    flex: 1;
    align-items: center;
  }

  @media only screen and (max-width: 576px) {
  }

  @media only screen and (max-width: 480px) {
  }

  @media only screen and (max-width: 360px) {
  }
`;

const Title = styled.h1`
  font-size: 50px;
  margin-bottom: 0;
  text-align: center;

  @media only screen and (max-width: 992px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }

  @media only screen and (max-width: 576px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 28px;
  }

  @media only screen and (max-width: 360px) {
    font-size: 24px;
  }

  span {
    color: #fff;
  }

  .exa-tech {
    color: rgba(150, 192, 211, 1); 
  }
`;

const Sub = styled.h2`
  text-align: center;
color:white;
  font-size: 17px; 
  margin-top: 0; 

  @media only screen and (max-width: 992px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 768px) {
  }

  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 360px) {
    font-size: 16px;
  }
`;

const Ext = styled.p`
  font-size: 15px;
  color: lightgray;

  @media only screen and (max-width: 992px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;
    text-align: center;
  }

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 360px) {
    font-size: 12px;
  }

  .exachain {
    color: rgba(150, 192, 211, 1);
  }
`;



const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const Button = styled.a`
  background-color: rgba(150, 192, 211, 1);
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  width: 180px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  @media only screen and (max-width: 992px) {
    width: 150px;
    padding: 8px;
  }

  @media only screen and (max-width: 768px) {
    width: 120px;
    padding: 6px;
    font-size: 14px;
  }

  @media only screen and (max-width: 576px) {
    width: 100px;
    padding: 5px;
    font-size: 12px;
  }

  @media only screen and (max-width: 480px) {
    width: 80px;
    padding: 4px;
    font-size: 10px;
  }

  @media only screen and (max-width: 360px) {
    width: 70px;
    padding: 4px;
    font-size: 9px;
  }
`;


const Right = styled.div`
  flex: 3;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  position: relative;
  top: 180px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  @media only screen and (max-width: 768px) {
    top: 70px;
  }

  @media only screen and (max-width: 390px) {
    top: 0; 
  }
`;


const LogoImg = styled.img`
  width: 350px;
  height: 369px;
  object-fit: contain;
  margin-top: ${props => props.higher ? '95px' : '0'};
  animation: ${props => props.animate ? 'moveUpDown 2s infinite ease alternate' : 'none'};

  @media only screen and (max-width: 1200px) {
    width: 300px;
    height: 312px;
    margin-top: ${props => props.higher ? '130px' : '0'};
  }

  @media only screen and (max-width: 992px) {
    width: 250px;
    
    height: 260px;
    margin-top: ${props => props.higher ? '100px' : '0'};
  }

  @media only screen and (max-width: 768px) {
    width: 200px;
    margin-left: 120px;
    height: 208px;
    margin-top: ${props => props.higher ? '0px' : '0'};
  }

  @media only screen and (max-width: 480px) {
  margin-bottom: -180px;


    
  }
 @media only screen and (max-width: 390px) {
    width: 150px;
    height: 156px;
    margin-top: ${props => props.higher ? '0px' : '0'};
    margin-bottom: -60px;
    margin-right: ${props => props.higher ? '20px' : '0'}; 
  }
  


  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(80px);
    }
  }
`;






const ExaTech = () => {
  const [textRef, textInView] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });

  return (
    <Section>
      <Container>
        <Left>
          <motion.div
            ref={textRef}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: textInView ? 0 : -100, opacity: textInView ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            <Title>
              <span>Build With</span> <span className="exa-tech">EXA_TECH</span>
            </Title>
            <Sub>Leveraging  innovative Artificial Intelligence technology to   secure world-class Web3 applications</Sub>
            <Ext>
       EXA_Tech operates the EXA_Chain ecosystem, a unique Proof of Artificial Intelligence (PoAI) algorithm based blockchain allowing developers to rapidly deploy tokens that support their applications in a safe and secure environment.
            </Ext>
            <ButtonsContainer>
              <ButtonsContainer>
                <Button href="https://pancakeswap.finance/swap?outputCurrency=0x623be4fde518A00AC49a870bd439cfD5c35E08Ed" target="_blank" rel="noopener noreferrer">BUY EXT</Button>
                <Button href="https://exatech.gitbook.io/exa_tech-whitepapper1/" target="_blank">Read Docs</Button>
              </ButtonsContainer>

            </ButtonsContainer>

          </motion.div>
        </Left>
        <Right>
          <ImagesContainer>
            <motion.div
              ref={imageRef}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: imageInView ? 0 : 100, opacity: imageInView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <LogoImg src="../images/poa.png" animate />
              <LogoImg src="../images/logo.png" />
            </motion.div>
          </ImagesContainer>
        </Right>
      </Container>
      <AvailabilityComponent />
    </Section>
  );
};

export default ExaTech;
