import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const RoadmapSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

const RoadmapTitle = styled.h2`
  margin-bottom: 30px;
  font-size: 40px;
  text-align: center;
  margin-top: 150px;

  @media only screen and (max-width: 768px) {
    font-size: 27px;
    margin-top: 100px;
  }
`;

const RoadmapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const RoadmapItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 15px;
  width: 150px;

  @media only screen and (max-width: 768px) {
    width: 130px;
  }

  @media only screen and (max-width: 480px) {
    width: 100px;
  }
`;

const RoadmapIcon = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 15px;
`;

const RoadmapText = styled.p`
  text-align: left;
  margin-bottom: -10px;

  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const PhaseText = styled(RoadmapText)`
  margin-bottom: 0;
  color: ${(props) => props.color || "black"};
`;
const TitlePart1 = styled.span`
  color: white;
`;



const roadmapItemAnimation = {
  hidden: { y: 10, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 2 } }, 
};

const RoadmapItemComponent = ({ item, color }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  return (
    <RoadmapItem
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : ""}
      variants={roadmapItemAnimation}
    >
      <RoadmapIcon src={item.image} alt={item.text} />
      <PhaseText color={color}>{item.text}</PhaseText>
      {item.details.map((detail, detailIndex) => (
        <RoadmapText key={detailIndex}>&bull; {detail}</RoadmapText>
      ))}
    </RoadmapItem>
  );
};

const Roadmapsection = () => {

  const roadmapData = [
        {
            image: "images/coin.png",
            text: "Phase 1",
            details: [
                "Build Team",
                "Website V1 Launch",
                "Build Smart Contract",
                "Community Building",
                "FairLaunch On DEX",
                "Further Marketing",
            ],
        },


        {
            image: "images/coin.png", text: "Phase 2", details: [
                "500 Holders",
                "Biggest Buy Contest",
                "Website V2",
                "EXT Explorer Launch",
                "Testnet launch",
                "Marketing",
            ],
        },

        {
            image: "images/coin.png", text: "Phase 3",
            details: [
                "1000 Holders",
                "EXT Bridge ",
                "EXT Mainnet",
                "Bridge Ex20",
                "Launch On Ex 20",
                "Security Audit",
                "Marketing",
            ],

        },

        {
            image: "images/coin.png", text: "Phase 4",
            details: [
                "5000 Holders",
                "Website V3 ",
                "Hackathon",
                "Staking",
                "Partnership",
                "News Media Global",
                "Marketing",
            ],
        },

        {
            image: "images/coin.png", text: "Phase 5",
            details: [
                "10000 Holders",
                "Tier 1 Cex listing ",
                "Yield Farming",
                "EXT DAO Live",
                "Tier 1 Cex listing",
                "EXT Stable Live",
                "Future Building",
            ],
        },
    ];
    const phaseColors = [
        "rgba(255, 230, 0, 1)",
        "rgba(0, 255, 41, 1)",
        "rgba(0, 240, 255, 1)",
        "rgba(143, 0, 255, 1)",
        "rgba(150, 192, 211, 1)"
    ];


 
  
    return (
      <RoadmapSection>
        <RoadmapTitle>
          <TitlePart1>Roadmap </TitlePart1>
   
        </RoadmapTitle>
        <RoadmapContainer>
          {roadmapData.map((item, index) => (
            <RoadmapItemComponent
              key={index}
              item={item}
              color={phaseColors[index % phaseColors.length]}
            />
          ))}
        </RoadmapContainer>
      </RoadmapSection>
    );
  };
  
  export default Roadmapsection;