import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  @media only screen and (min-width: 769px) {
    height: 100vh;
    padding: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 769px) {
    height: 100%;
    width: 1400px;
    flex-direction: row;
  }
`;


const Left = styled.div`
  color: rgba(150, 192, 211, 1);
  text-align: center;

  @media only screen and (min-width: 769px) {
    text-align: left;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  }
`;


const TitleMotion = styled(motion.h1)`
  font-size: 30px;
  margin-bottom: 0;
  text-align: center;

  @media only screen and (min-width: 769px) {
    font-size: 50px;
  }

  span {
    color: #fff;
  }

  .exa-tech {
    color: rgba(150, 192, 211, 1);
  }
`;

const ExtMotion = styled(motion.p)`
  font-size: 16px;
  color: lightgray;
  text-align: center;

  @media only screen and (min-width: 769px) {
    font-size: 20px;
    position: relative;
    right: -20%;
    text-align: left;
  }

  .exachain {
    color: rgba(150, 192, 211, 1);
  }
`;

const Right = styled.div`
  width: 100%;

  @media only screen and (min-width: 769px) {
    flex: 3;
    position: relative;
  }
`;

const HighlightedText = styled.span`
  font-size: 22px;
  color: rgba(150, 192, 211, 1);

  @media only screen and (min-width: 769px) {
    font-size: 30px;
  }
`;
const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30%;
  right: 0;
  margin: auto;

  @media only screen and (max-width: 768px) {
    position: relative;
  
    

  }
  @media only screen and (max-width: 480px) {
  margin-right: 120px;
  
 
}
`;

const LogoImg = styled.img`
  width: 250px;
  height: 269px;
  object-fit: contain;
  position: ${props => props.higher ? 'absolute' : 'static'}; 
  top: ${props => props.higher ? '195px' : '0'}; 
  animation: ${props => props.animate ? 'moveUpDown 2s infinite ease alternate' : 'none'};

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(80px);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 200px;
    height: 215px;

  }

  @media only screen and (max-width: 480px) {

    width: 150px;
    height: 161px;
  }

  @media only screen and (max-width: 360px) {

    width: 120px;
    height: 129px;
  }
`;








const Low = () => {
  const [titleRef, titleInView] = useInView({ threshold: 0.5 });
  const [textRef1, textInView1] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });


  const textAnimation = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = { duration: 1 };
  return (
    <Section>
      <Container>
        <Left>
          <TitleMotion
            ref={titleRef}
            initial="hidden"
            animate={titleInView ? "visible" : "hidden"}
            variants={textAnimation}
            transition={transition}
          >
            AI Real Low Fees
          </TitleMotion>
          <ExtMotion
            ref={textRef1}
            initial="hidden"
            animate={textInView1 ? "visible" : "hidden"}
            variants={textAnimation}
            transition={transition}
          >
            <HighlightedText>EXATech</HighlightedText> is a blockchain network that aims to provide low transaction fees for its users. Low fees is important for blockchain users who want to save costs when making transactions. One of the ways that EXA_Tech achieves low fees is using a hybrid consensus algorithm. This algorithm allows fast block times and high throughput, which helps transaction fees keep low. Additionally, EXA_Tech uses a sharding mechanism to distribute transaction processing across multiple shards, which also helps to increase the network's overall capacity and efficiency.
          </ExtMotion>
        </Left>
        <Right>
          <ImagesContainer>
            <motion.div
              ref={imageRef}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <LogoImg src="../images/poa.png" animate />
              <LogoImg src="../images/low.png" />
            </motion.div>
          </ImagesContainer>
        </Right>
      </Container>
    </Section>
  );
};

export default Low;

