import React from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 321px) {
    padding: 50px 0;
    margin-top: 130px;
    margin-bottom: -60px;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 50px 0;
    margin-top: 130px;
    margin-bottom: -60px;
  }
`;

const Container = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

const Left = styled.div`
  flex: 1;
  color: #000;

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    order: 2;
  }
`;

const Title = styled.h1`
  font-size: 40px;

  @media only screen and (max-width: 992px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 321px) {

    margin-top:36%;
  }
`;

const Right = styled.div`
  margin-top: -20%;
  margin-left: 6%;
  display: flex;
  width:50%;
  flex-direction: column;
  justify-content: center;
  color: rgba(150, 192, 211, 1);
  position: relative;

  @media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
    margin-top: 0;
    width:auto;
    margin-left: 0;
    margin-bottom: 30px; 
`;

const Xai = styled.p`
  font-size: 20px;
  color: lightgray;

  .poai {
    font-size: 35px;
    color: rgba(150, 192, 211, 1);
  }

  @media only screen and (max-width: 992px) {
    font-size: 18px;

    .poai {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
 
  position: relative;
  top: 0;
  left: 0;

  @media only screen and (max-width: 768px) {
    align-item:center;
 
    margin-bottom: 30px;
`;

const LogoImg = styled.img`
  width: 300px;
  height: 469px;
  object-fit: contain;
  position: ${props => props.higher ? 'absolute' : 'static'};
  top: ${props => props.higher ? '195px' : '0'};
  animation: ${props => props.animate ? 'moveUpDown 2s infinite ease alternate' : 'none'};

  @media only screen and (max-width: 992px) {
    width: 200px;
    height: 313px;
    top: ${props => props.higher ? '130px' : '0'};
  }

  @media only screen and (max-width:  768px) {
    width: 150px;
    height: 234px;
    top: ${props => props.higher ? '100px' : '0'};
    margin: 20px;
    margin-bottom: 30px;
  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(50px);
    }
  }
`;


function Aireal() {
  const [textRef, textInView] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });



  return (
    <Section>
      <Container>
        <Left>
          <ImagesContainer>
          <motion.div
          ref={imageRef}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }}
          transition={{ duration: 1 }}
        >
            <LogoImg src="../images/poa.png" animate />
            <LogoImg src="../images/speed.png" />
            </motion.div>
          </ImagesContainer>
        </Left>
        <Right>
        <motion.div
        ref={textRef}
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: textInView ? 0 : 100, opacity: textInView ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
          <Title>AI Real Time Speed</Title>
          <Xai>
            <span className="poai"> EXA_Tech</span>  Al Real Time Speed refers to the ability of the EXA Tech network to process large volumes of data and perform complex calculations in real-time using artificial intelligence (AI) technology. The high-performance capabilities of the EXA_Tech network allow it to handle large data sets quickly and efficiently, which is critical in many applications where real-time analysis and decision-making are required. EXA_Tech Al Real Time Speed refers to the ability of the EXA_Tech network to process large volumes of data and perform complex calculations in real-time using Artificial Intelligence (AI) Technology. With the Al algorithm EXATech blockchain is capable to validate transaction in real-time and work efficiently to handle more than Up to 450,000 Transactions Per Second (TPS).
          </Xai>
          </motion.div>
        </Right>
      </Container>
    </Section>
  );
};

export default Aireal;

