import React from "react";
import styled from "styled-components";
import Footer from "./Footer ";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    height: 100vh;
  }
`;

const VideoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
  overflow: hidden;
  padding-top: 45%;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const TextContainer = styled.div`
  margin-top: 30px;
`;

const Title = styled.h3`
  font-size: 50px;
  text-align: center;
  margin-bottom: -60px;
`;

const Subtitle = styled.h4`
  font-size: 50px;
  text-align: center;
  color: rgba(150, 192, 211, 1);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const SocialButton = styled.a`
  background-color: rgba(150, 192, 211, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px 0;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.2s;
  width: 150px;
  text-align: center;

  &:hover {
    background-color: #0e82b8;
  }
`;






const VideoSection = () => {
  const videoSrc = "images/EXA_Tech.mp4";
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const videoContainerAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };


  

 
    return (
      <Section ref={ref}>
        <VideoContainer>
          <motion.div
            initial="hidden"
            animate={inView ? "visible" : ""}
            variants={videoContainerAnimation}
          >
            <VideoWrapper>
              <Video src={videoSrc}  autoPlay muted loop />
            </VideoWrapper>
            <TextContainer>
              <Title>Join Our Community</Title>
              <Subtitle>EXATech</Subtitle>
            </TextContainer>
            <ButtonContainer>
              <SocialButton
                href="https://t.me/EXATechOfficialEN"
                target="_blank"
                buttonColor="#1da1f2"
                textColor="white"
              >
                Telegram
              </SocialButton>
              <SocialButton
                href="https://twitter.com/ExaTechPoAI"
                target="_blank"
                buttonColor="#1da1f2"
                textColor="white"
              >
                Twitter
              </SocialButton>
              <SocialButton
                href="https://medium.com/@exatechofficialen"
                target="_blank"
                buttonColor="#1da1f2"
                textColor="white"
              >
                Medium
              </SocialButton>
            </ButtonContainer>
          </motion.div>
        </VideoContainer>
        <Footer />
      </Section>
    );
  };
 export default VideoSection;