import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  color: white;
  font-size: 14px;
  with:100%;
  width: 100%;
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  margin-left: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      &copy; 2023 EXATech, All Rights Reserved.{" "}
      <FooterLink href="#">Risk Disclosure Statement</FooterLink> {" "}
      <FooterLink href="#">Personal Data & Privacy Policy</FooterLink>
    </FooterContainer>
  );
};


export default Footer;