import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";


const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    margin-top: 120px;
    height: auto;
    padding-bottom: 50px;
  }
`;

const Container = styled.div`
  height: 100%;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1400px) {
    width: 90%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Left = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  color:rgba(150, 192, 211, 1); 
  @media only screen and (max-width: 768px) {
    flex: 1;
    align-items: center;
  }
`;
const TitleMotion = styled(motion.h1)`
  font-size: 50px;
  margin-bottom: 0;
  text-align: center;

  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 40px;
  }

  span {
    color: #fff;
  }

  .exa-tech {
    color:rgba(150, 192, 211, 1); 
  }
`;

const ExtMotion = styled(motion.p)`
  font-size: 20px;
  color: lightgray;
  position: relative;
  right: -20%;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    right: 0;
  }

  .exachain {
    color: rgba(150, 192, 211, 1);
  }
`;

const Right = styled.div`
  flex: 3;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30%;
  right: 0;
  margin: auto;

  @media only screen and (max-width: 768px) {
    position: static;
    flex-direction: column;
    justify-content: center;
    margin-top: 20%;
  }
`;

const LogoImg = styled.img`
  width: 250px;
  height: 269px;
  object-fit: contain;
  position: ${props => props.higher ? 'absolute' : 'static'};
  top: ${props => props.higher ? '195px' : '0'};
  animation: ${props => props.animate ? 'moveUpDown 2s infinite ease alternate' : 'none'};

  @media only screen and (max-width: 992px) {
    width: 200px;
    height: 215px;
    top: ${props => props.higher ? '156px' : '0'};
  }

  @media only screen and (max-width: 768px) {
    width:   width: 150px;
    height: 161px;
    top: ${props => props.higher ? '117px' : '0'};
    animation: ${props => props.animate ? 'moveUpDown 1.5s infinite ease alternate' : 'none'};
  
    @keyframes moveUpDown {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(80px);
      }
    }
  `;
  



const HighlightedText = styled.span`
  font-size: 30px;
  color: rgba(150, 192, 211, 1);

}
`;

const AiSecurity = () => {
  const [titleRef, titleInView] = useInView({ threshold: 0.5 });
  const [textRef1, textInView1] = useInView({ threshold: 0.5 });
  const [textRef2, textInView2] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });


  const textAnimation = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = { duration: 1 };

  return (
    <Section>
      <Container>
        <Left>
          <TitleMotion
            ref={titleRef}
            initial="hidden"
            animate={titleInView ? "visible" : "hidden"}
            variants={textAnimation}
            transition={transition}
          >
            AI Security
          </TitleMotion>
          <ExtMotion
            ref={textRef1}
            initial="hidden"
            animate={textInView1 ? "visible" : "hidden"}
            variants={textAnimation}
            transition={transition}
          >
            <HighlightedText>With</HighlightedText>the implementation of Al, Blockchain technology becomes safer by making secure future application deployments. Al algorithms which are making decisions about  financial transactions whether increase fraudulently and should be blocked or investigated, are good example of it. EXA TECH will guarantee this.
          </ExtMotion>
          <ExtMotion
          ref={textRef2}
          initial="hidden"
          animate={textInView2 ? "visible" : "hidden"}
          variants={textAnimation}
          transition={transition}
        >
        <HighlightedText>POAI</HighlightedText>  security system on the EXA_Tech network is designed to provide a high level of security and protection for the data stored on the blockchain. It utilizes several key security features, including: Encryption, Distributed Ledger Technology, Consensus Mechanism, Node Authentication, Multi-Factor Authentication and Audit Trail.
            </ExtMotion>
            </Left>
            <Right>
              <ImagesContainer>
              <motion.div
              ref={imageRef}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
                <LogoImg src="../images/poa.png" animate />
                <LogoImg src="../images/safety.png" />
                </motion.div>
              </ImagesContainer>
            </Right>
          </Container>
        </Section>
      );
    };
    
    export default AiSecurity;
