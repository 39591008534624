import React from "react";
import styled from "styled-components";
import TokenomicsChart from './TokenomicsChart';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";






const Section = styled.div`
height: auto;
scroll-snap-align: center;
display: flex;
flex-direction: column;
align-items: center;
padding: 50px 20px;

  @media only screen and (max-width: 768px) {
    height: auto;
    margin-top: 120px;
   
  }
`;

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;


  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:20%
 
`;

const TitleMotion = styled(motion.h1)`
  position: relative;

  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-bottom: 0;

  @media only screen and (max-width: 768px) {

    left:0;
    right:0;
 
    font-size: 30px;
  }

 span {
    color: #fff;
  }

  .exa-tech {
    color: rgba(96, 165, 250, 1);
  }
  @media only screen and (min-width: 321px) {
width:30%
    font-size: 20px;
   }


`;

const Right = styled.div`

  position: relative;
  @media only screen and (max-width: 768px) {
    flex: 3;
    width: 100%;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  position: realtive;
  top: 60%;
  bottom: 0;
  
  right: 0;
  margin: auto;

  @media only screen and (max-width: 768px) {
    position: relative;
    margin-top: 30px;
  
    
  
  }

  .tokenomics-chart {
    margin-top: 450px; 
    left: 0%;
    
    
  }
  
`;


const Distribution = () => {
  const [titleRef, titleInView] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });


  const textAnimation = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = { duration: 1 };




  return (
    <Section>
      <Container>
        <Right>
          <ImagesContainer>
            <motion.div
              ref={imageRef}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <TextContainer>
                <TitleMotion
                  ref={titleRef}
                  initial="hidden"
                  animate={titleInView ? "visible" : "hidden"}
                  variants={textAnimation}
                  transition={transition}
                >
                  Token Distribution <span className="exa-tech">EXATech</span>
                </TitleMotion>
              </TextContainer>
              <TokenomicsChart />
            </motion.div>
          </ImagesContainer>
        </Right>
      </Container>
    </Section>
  );
};
export default Distribution;