import React, { useEffect, useState } from 'react';
import './Price.css';

async function getDataInfo () {
  try {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const CRYPTO_SYMBOLS = process.env.REACT_APP_CRYPTO_SYMBOLS;
    
    const url = `${API_BASE_URL}?symbols=${CRYPTO_SYMBOLS}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();

    console.log(data); 

    const coins = ['EXT', 'BTC', 'ETH', 'LTC', 'BNB', 'ARB', 'USDT', 'XRP', 'ADA', 'MATIC'];
    const coinData = coins.map(coin => {
      const price = data?.data?.[coin]?.quote?.USD?.price;
      return {
        coin,
        price: price ? `$${price.toFixed(10)}` : "N/A",
      };
    });

    return coinData;
  } catch (error) {
    console.error(error);
    return null;
  }
}


const Price = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDataInfo()
    .then(extData => {
      setData(extData); 
      console.log(extData);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);
  

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="price-container">
      {data ? (
        data.map(({coin, price}) => (
          <div key={coin} className="price-item">
            <h2>{coin}</h2>
            <h3>Price: {price}</h3>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default Price;
