import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Image = styled(motion.img)`
  width: auto;
  height: 50px;
  object-fit: contain;

  @media (max-width: 1024px) {
    height: 45px; 
  }

  @media (max-width: 768px) {
    height: 40px; 
  }

  @media (max-width: 480px) {
    height: 15px; 
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; 

  @media (max-width: 1024px) {
    gap: 25px;
  }

  @media (max-width: 768px) {
    gap: 20px; 
  }

  @media (max-width: 480px) {
    gap: 15px; 
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const StyledP = styled(motion.p)`
  margin-bottom: 100px; 
  font-size: 1.5em;

  @media (max-width: 1024px) {
    font-size: 1.3em;
  }

  @media (max-width: 768px) {
    font-size: 1.1em; 
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    font-size: 0.9em; 
    
  }
`;






const AvailabilityComponent = () => {
  const animation = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);


    return (
      <Container ref={ref}>
        <StyledP
          animate={animation}
          initial="hidden"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -100 },
          }}
          transition={{ duration: 0.5 }}
        >
          EXT is available on
        </StyledP>
     
      <Row>
        <a href="https://coinmarketcap.com/currencies/exatech-poai-blockchain/" target="_blank" rel="noopener noreferrer">
          <Image
            src="../images/cmc.png"
            alt="cmc"
            animate={animation}
            initial="hidden"
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.5 }}
          />
        </a>
   
        <a href="https://pancakeswap.finance/swap?outputCurrency=0x623be4fde518A00AC49a870bd439cfD5c35E08Ed" target="_blank" rel="noopener noreferrer">
          <Image
            src="../images/pancakeswap.png"
            alt="pancakeswap"
            animate={animation}
            initial="hidden"
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.5 }}
          />
        </a>
        <a href="https://bscscan.com/token/0x623be4fde518a00ac49a870bd439cfd5c35e08ed" target="_blank" rel="noopener noreferrer">
          <Image
            src="../images/BscScan.png"
            alt="bscscan"
            animate={animation}
            initial="hidden"
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.5 }}
          />
        </a>
      </Row>
    </Container>
  );
};

export default AvailabilityComponent;