import React from 'react';
import underdevelopment from '../images/underdevelopment.jpg';
import './underdevelopment.css';

const Dapps = () => {
  return (
    <div className="hero">
      <img src={underdevelopment} alt="Under Development" />
    </div>
  );
};

export default Dapps;
