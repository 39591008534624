import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import logo1 from './images/logo1.png';
import styled from 'styled-components';

const TokenomicsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px; 

  @media only screen and (min-width: 321px) {
    margin-top: 70px; 
  }

  @media only screen and (min-width: 769px) {
    margin-top: 100px; 
  }
`;
const ChartWrapper = styled.div`
  position: relative;
  width: 90%;
  height: 200px;

  @media only screen and (min-width: 321px) {
    height: 300px;
    width: 70%;
  }

  @media only screen and (min-width: 769px) {
    height: 400px;
    max-width: 600px;
    width: 100%;
  }
`;




const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;
const LegendItem = ({ color, label }) => (
  <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
    <div
      style={{
        backgroundColor: color,
        width: '16px',
        height: '16px',
        
        marginTop:'5px',
        marginRight: '5px',
      }}
    ></div>
    <span>{label}</span>
  </div>
);

const TokenomicsChart = () => {
  const data = {
    labels: ['Liquidity', 'Marketing', 'Development', 'Partnership'],
    datasets: [
      {
        data: [70, 10, 10, 10],
        backgroundColor: ['#3FC5F0', '#16FF00', '#FFEA20', '#851DE0'],
        hoverBackgroundColor: ['#3FC5F0', '#16FF00', '#FFEA20', '#851DE0'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const legendItems = data.labels.map((label, index) => (
    <LegendItem key={label} color={data.datasets[0].backgroundColor[index]} label={label} />
  ));

  return (
    <TokenomicsChartContainer>
      <ChartWrapper>
        <Doughnut data={data} options={options} style={{ position: 'relative', width: '100%', height: '100%' }} />
        <img
          src={logo1}
          alt="Logo"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50px',
            height: '50px',
            zIndex: 2,
          }}
        />
      </ChartWrapper>
      <LegendContainer>{legendItems}</LegendContainer>
    </TokenomicsChartContainer>
  );
};
export default TokenomicsChart;
