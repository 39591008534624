import React from 'react';
import './App.css';
import styled from 'styled-components';
import AnimatedNavbar from "./components/Nav/AnimatedNavbar";
import AiSecurity from './components/AiSecurity';
import Exa from './components/Exa';
import "./exabg.css";
import ExaTech from './components/ExaTech';
import Aireal from './components/Aireal';
import Low from './components/Low';
import Ecosystem from './components/Ecosystem';
import Distribution from './components/Distribution';
import Roadmapsection  from './components/Roadmapsection ';
import VideoSection from "./components/VideoSection";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ExaTechDeveloperTools from './components/Pages/ExaTechdevelopertools';
import Explorer from './components/Pages/Explorer';
import Dapps from './components/Pages/Dapps';
import Price from "./components/Price";






const Container = styled.div`
  min-height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), #000000;

  &::-webkit-scrollbar{
    display: none;
  }
`;

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Container>
        <AnimatedNavbar />
        <Switch>
          <Route exact path="/" render={() => (
            <>
            <Price />
              <ExaTech />
           
              <Exa />
              <AiSecurity />
              <Aireal />
              <Low />
              <Ecosystem />
              <Distribution />
              <Roadmapsection />
              <VideoSection />
            </>
          )} />
          <Route path="/exatech-developer-tools" component={ExaTechDeveloperTools} />
          <Route path="/Explorer" component={Explorer} />
          <Route path="/Dapps" component={Dapps} />
        </Switch>
      </Container>
    </Router>
  );
}

export default App;

