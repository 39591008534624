import React from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Section = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 

  @media only screen and (min-width: 769px) {
    height: 100vh;
    width: 1400px;
  }
`;

const TextContainer = styled.div`
  text-align: center;
`;

const TitleMotion = styled(motion.p)`
  font-size: 20px;
  display: inline; 

  @media only screen and (min-width: 321px) {
    font-size: 25px;
  }

  @media only screen and (min-width: 769px) {
    font-size: 40px;
  }
`;

const ImagesContainer = styled.div`
  margin-top: 30px;
`;

const LogoImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;

  @media only screen and (min-width: 769px) {
    width: 700px;
    height: 769px;
  }
`;


const BottomTextMotion = styled(motion.p)`
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  max-width: 700px; 

  @media only screen and (min-width: 321px) {
    font-size: 22px;
  }

  @media only screen and (min-width: 769px) {
    font-size: 30px;
  }
`;

const HighlightedText = styled.span`
  font-size: 22px;
  color: rgba(150, 192, 211, 1);

  @media only screen and (min-width: 769px) {
    font-size: 30px;
  }
`;

const Ecosystem = () => {
  const [titleRef, titleInView] = useInView({ threshold: 0.5 });
  const [textRef2, textInView2] = useInView({ threshold: 0.5 });
  const [imageRef, imageInView] = useInView({ threshold: 0.5 });


  const textAnimation = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = { duration: 1 };





  return (
    <Section>
      <Container>
        <TextContainer>
        <TitleMotion
        ref={titleRef}
        initial="hidden"
        animate={titleInView ? "visible" : "hidden"}
        variants={textAnimation}
        transition={transition}
      > Join to be a contributor a <HighlightedText>EXATech</HighlightedText> 
      
       </TitleMotion>
        
        </TextContainer>
        <ImagesContainer>
        <motion.div
        ref={imageRef}
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
          <LogoImg src="../images/ecosystem.png" />
          </motion.div>
        </ImagesContainer>
      
        <BottomTextMotion
        ref={textRef2}
        initial="hidden"
        animate={textInView2 ? "visible" : "hidden"}
        variants={textAnimation}
        transition={transition}
      >
          <HighlightedText> EXATech </HighlightedText>ecosystem can provide you with the necessary resources to scale your project, with the assistance of our dedicated community and development teams.
        </BottomTextMotion>
      </Container>
    </Section>
  );
};

export default Ecosystem;
