import React, { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei';
import './Navbar.css';
import logo from '../images/logo.png';

const AnimatedLogo = () => {
    const meshRef = useRef();

    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.rotation.y += 0.007;
        }
    });

    return (
        <mesh ref={meshRef} position={[0, 0, -5]}>
            <boxGeometry args={[8, 8, 8]} />
            <meshStandardMaterial map={new THREE.TextureLoader().load(logo)} />
        </mesh>
    );
};

const AnimatedNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({ products: false, dapps: false });

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = (dropdown) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [dropdown]: !prevState[dropdown],
        }));
    };


    const handleDropdownClick = (dropdown) => {
        if (window.innerWidth <= 768) {
          setDropdownOpen((prevState) => ({
            ...prevState,
            [dropdown]: !prevState[dropdown],
          }));
        }
      };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <a href="/">
                <div style={{ width: '100px', height: '100px' }}>
                <Canvas
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none' 
                    }}
                >
                    <ambientLight intensity={0.5} />
                    <pointLight position={[10, 10, 10]} />
                    <AnimatedLogo />
                    <OrbitControls args={[null, null, null]} enableZoom={false} />
                </Canvas>
              </div>
              
                   
                </a>
            </div>
            <button className={`navbar-menu-button${menuOpen ? ' open' : ''}`} onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </button>
            <ul className={`navbar-menu${menuOpen ? ' open' : ' collapsed'}`}>
                <li className="menu-item" style={menuOpen ? { animation: "slideFromLeft 0.5s forwards", animationDelay: "0.2s" } : {}}>
                    <a href="/about" onClick={toggleMenu}>About</a>
                </li>
                <li
                    className="menu-item dropdown"
                    onMouseEnter={() => toggleDropdown('products')}
                    onMouseLeave={() => toggleDropdown('products')}
                    onClick={() => handleDropdownClick('products')}
                    style={menuOpen ? { animation: 'slideFromLeft 0.5s forwards', animationDelay: '0.3s' } : {}}
                >
                    <a href="/our-products" onClick={(e) => e.preventDefault()}>
                        Our Products <span className="vector">▼</span>
                    </a>
                    <ul className={`dropdown-menu${dropdownOpen.products ? ' open' : ''}`}>
                        <li>
                        <a href="/exatech-developer-tools" onClick={toggleMenu}>ExaTech Developer Tools</a>
                        </li>
                        <li>
                            <a href="https://ext-explorer.com/" onClick={toggleMenu}>Explorer</a>
                        </li>
                    </ul>
                </li>
                <li
                    className="menu-item dropdown"
                    onMouseEnter={() => toggleDropdown('dapps')}
                    onMouseLeave={() => toggleDropdown('dapps')}
                    onClick={() => handleDropdownClick('dapps')}
                    style={menuOpen ? { animation: 'slideFromLeft 0.5s forwards', animationDelay: '0.4s' } : {}}
                >
                    <a href="/Dapps" onClick={(e) => e.preventDefault()}>
                        Dapps <span className="vector">▼</span>
                    </a>
                    <ul className={`dropdown-menu${dropdownOpen.dapps ? ' open' : ''}`}>
                        <li>
                            <a href="/Dapps" onClick={toggleMenu}>Yield Farming</a>
                        </li>
                    </ul>
                </li>
                <li className="menu-item" style={menuOpen ? { animation: "slideFromLeft 0.5s forwards", animationDelay: "0.5s" } : {}}>
                    <a href="/ecosystem" onClick={toggleMenu}>Ecosystem</a>
                </li>
                <li className="menu-item" style={menuOpen ? { animation: "slideFromLeft 0.5s forwards", animationDelay: "0.6s" } : {}}>
                    <a href="https://t.me/EXATechOfficialEN" onClick={toggleMenu}>Community</a>
                </li>
                <li className="menu-item" style={menuOpen ? { animation: "slideFromLeft 0.5s forwards", animationDelay: "0.7s" } : {}}>
                <a href="https://exatech.gitbook.io/exa_tech-whitepapper1/" onClick={toggleMenu}>Whitepaper</a>
              
                </li>
            </ul>

        </nav>
    );
};

export default AnimatedNavbar;